<template>
    <div :class="['adstyleoption-container',{'img-right':listOrder%2===1}]">
        <div class="content">
            <div>
                <img :src="adOption.img" alt="">
                <div>
                    <h3>{{adOption.title}}</h3>
                    <p>{{adOption.titleIntro}}</p>
                </div>
            </div>
            <div>
                <h5><i></i>场景优势</h5>
                <p>{{adOption.advantage}}</p>
            </div>
            <div>
                <h5><i></i>广告样式</h5>
                <p>{{adOption.style}}</p>
            </div>
            <div v-if='adOption.cutCode'>
                <span :class="showImgOrder===0?'active':''" @click='showImgOrder=0'>{{adOption.cutCode[0]}}</span>
                <span :class="showImgOrder===1?'active':''" @click='showImgOrder=1'>{{adOption.cutCode[1]}}</span>
            </div>
        </div>
        <div class="img-container">
            <div>
                <img :src="adOption.imgDetail[showImgOrder]" alt="样式案例">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            listOrder: {
                type: Number,
                default:1 
            },
            adOption:{
                type:Object,
                required:true
            }
        },
        data() {
            return {
                isActive:true,
                showImgOrder:0,
            }
        },
        
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .adstyleoption-container{
        height: 446px;
        box-shadow: 0 0 20px 0 rgba(135,135,135,10%);
        background:white;
        border-radius: 20px;
        padding: 0 80px;
        position: relative;
        &.img-right{
            .img-container{
                right: 120px;
            }
            .content{
                float:left;
            }
        }
        .img-container{
            position: absolute;
            bottom: -84px;
            width: 420px;
            height: 678px;
            background: rosybrown;
            background: url(../assets/img/form-phone.png) center no-repeat;
            background-size: contain;
            >div{
                width: 317px;
                height: 565px;
                position: absolute;
                bottom: 0;
                left: 53px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content{
            float:right;
            padding:0 80px;
            width: 636px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            >div{
                margin: 16px 0;
                h5{
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    i{
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 3px;
                        margin-right: 6px;
                        background: @themeColor;
                    }
                }
                >p{
                    font-size: 14px;
                    color:#767c87;
                    line-height: 22px;
                    padding-left: 12px;
                }
                >span{
                    padding: 5px 14px;
                    font-size: 14px;
                    letter-spacing: -.04px;
                    line-height: 14px;
                    border: 1px solid #ddd;
                    cursor: pointer;
                    color: #c1c1c1;
                    transition: .1s;
                    &:first-child{
                        border-radius: 4px 0 0 4px;
                        border-right: none;
                    }
                    &:last-child{
                        border-radius: 0 4px 4px 0;
                        border-left: none;
                    }
                    &:hover{
                        background: #efefef;
                    }
                    &.active{
                        background: @themeColor;
                        color: white;
                    }
                }
            }
            >div:nth-child(1){
                display: flex;
                img{
                    width: 68px;
                    height: 68px;
                    margin-left: -10px;
                    margin-right: 10px;
                }
                h3{
                    font-weight: 600;
                    font-size: 28px;
                }
                p{
                    color:#767c87;
                    font-size: 18px;
                    margin-top: 4px;
                }
            }
        }
    }
</style>