<template>
  <div class="adstyle">
    <div class="banner">
      <div class="banner-container">
        <h2>丰富的广告形态</h2>
        <h4></h4>
        <p>充分适配各类应用变现场景，更多曝光、更高收益、更好体验、更丰富多样的变现能力助力开发者。</p>
        <img src="../assets/img/ad-style.png" alt="">
      </div>
    </div>
    <div class="options-container">
      <div :class="['ad-options',{'is-fixed':isOptionsFixed}]">
      <ul>
        <li 
          v-for="item,index in adOptions" 
          :key="index" 
          :class="{active:index===adOptionIndex}"
          @click='handleShowOption(index)'>
            <img :src="item.img" alt="">
            <span>{{item.title}}</span>
        </li>
      </ul>
    </div>
    </div>
    <div class="style-detail">
      <div class="ad_01" v-for="item,index in 8" :key="index">
        <div class="ad_01_container">
          <AdStyleOption :list-order="index" :ad-option="adOptions[index]"/>
        </div>
      </div>
    </div>
    <div class="join-us">

    </div>
  </div>
</template>

<script>
  import AdStyleOption from '../components/AdStyleOption.vue' 
  export default {
    components:{
      AdStyleOption
    },
    data() {
      return {
        adOptions: [{
          img:require('../assets/img/ad_01_b.png'),
          title:'开屏广告',
          titleIntro:'应用开屏黄金展示位，头部品牌广告主青睐',
          advantage:'App开启后全屏沉浸式展现的广告，用户注意力集中，点击率高，eCPM高，品牌曝光效果好。支持5s以下视频素材展示，转化效果更好',
          style:'竖版大图、竖版视频、横版视频',
          imgDetail:[require('../assets/img/01.gif'),require('../assets/img/01-1.gif')],
          cutCode:['竖版图片','视频']
        },{
          img:require('../assets/img/ad_04_b.png'),
          title:'横幅广告',
          titleIntro:'在App顶/中/底部悬浮且可关闭的边栏广告',
          advantage:'可灵活选择版位的标准横幅广告，样式美观灵活，曝光率优异，点击率更高，预算收益提升',
          style:'横版图文',
          imgDetail:[require('../assets/img/03.gif')],
          cutCode:null
        },{
          img:require('../assets/img/ad_02_b.png'),
          title:'信息流广告',
          titleIntro:'广告与App内容高度自然融合的信息流',
          advantage:'让用户有浑然一体的使用体验，用户接受程度高，广告转化效果好',
          style:'竖版大图、竖版视频、横版视频',
          imgDetail:[require('../assets/img/04.gif')],
          cutCode:null
        },{
          img:require('../assets/img/ad_06_b.png'),
          title:'插屏广告',
          titleIntro:'在操作切换间弹出，充分渗透用户等待时间，拥有强信息表现力',
          advantage:'在弹出窗口上展示的广告。支持半屏图文、半屏视频和全屏视频三大样式，eCPM高，转化效果好',
          style:'半屏图文、半屏视频和全屏视频',
          imgDetail:[require('../assets/img/02.gif'),require('../assets/img/02-1.gif')],
          cutCode:['竖版插屏','全屏插屏'],
        },{
          img:require('../assets/img/ad_03_b.png'),
          title:'全屏广告',
          titleIntro:'全屏播放的视频广告',
          advantage:'全屏播放的视频广告，5秒可跳过，高收益的同时兼顾用户感受，小说翻页、操作完成页、游戏结束页等',
          style:'15～30秒竖版视频、横版视频',
          imgDetail:[require('../assets/img/05.gif')],
          cutCode:null
        },{
          img:require('../assets/img/ad_09_b.png'),
          title:'激励视频',
          titleIntro:'完整观看视频广告以免费获得游戏奖励',
          advantage:'用户通过完整观看视频来免费获取游戏或APP的奖励，与游戏或APP流程高度契合，eCPM高，转化效果好',
          style:'横版视频、竖版视频',
          imgDetail:[require('../assets/img/06.gif')],
          cutCode:null
        },{
          img:require('../assets/img/ad_08_b.png'),
          title:'模板广告',
          titleIntro:'与应用环境相契合的广告',
          advantage:'广告与APP内容高度自然融合，用户接受程度高',
          style:'图片、视频',
          imgDetail:[require('../assets/img/07.gif')],
          cutCode:null
        },{
          img:require('../assets/img/ad_07_b.png'),
          title:'模板视频信息流',
          titleIntro:'与抖音竖版视频流相似的视频信息流广告',
          advantage:'应用在竖版视频列表页，竖版视频信息流，来电秀、直播间等全屏观看的，切换过程中等，提供沉浸式无干扰用户体验，转化率更高',
          style:'视频',
          imgDetail:[require('../assets/img/08.gif')],
          cutCode:null
        }],
        adOptionIndex:0,
        isOptionsFixed:false,
        timer:null
      }
    },
    methods: {
      handleShowOption(i) {
        this.adOptionIndex = i
        document.getElementsByTagName('html')[0].scrollTop = 596 + 746*i
      },
      handleScroll(){
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        this.isOptionsFixed = scrollTop>=560 ? true :false
        this.timer && clearTimeout(this.timer)//这里做了一个防抖节流
        this.timer = setTimeout(()=>{
          let i = Math.round((scrollTop-596)/746)
          this.adOptionIndex = i > 7 ? 7 : i < 0 ? 0 : i
        },10)
      }
    },
    mounted(){
      window.addEventListener('scroll',this.handleScroll,true)
    },
    destroyed(){
      window.removeEventListener('scroll',this.handleScroll,true)
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/theme-color.less');
  .adstyle{
    .banner{
      height: 540px;
      background-image:url('../assets/img/type-banner-bg.png');
      background-size: contain;
      .banner-container{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2{
          font-size: 40px;
          font-weight: 600;
        }
        h4{
          background: @themeColor;
          width: 32px;
          height: 4px;
          margin: 12px 0 24px;
        }
        p{
          font-size: 18px;
          width: 530px;
        }
        img{
          width: 760px;
          position: absolute;
          top: -36px;
          right: -60px;
        }
      }
    }
    .options-container{
      position: relative;
      .ad-options{
        height: 120px;
        position: absolute;
        width: 100%;
        top: -55px;
        z-index: 101;
        &.is-fixed{
          position: fixed;
          top: 0;
          left: 0;
          background: white;
          box-shadow: 0 6px 12px 0 rgba(0, 0 ,0 ,5%);
          >ul{
            border-radius: 0;
            box-shadow:none;
          }
        }
        ul{
          width: 1200px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          box-shadow: 0 6px 12px 0 rgba(0, 0 ,0 ,5%);
          align-items: center;
          border-radius: 8px;
          overflow: hidden;
          background: white;
          li{
            width: 25%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: .2s;
            &.active{
              background: #ceeefa99;
              span{
                color:#28a5dc;
              }
            }
            &:hover{
              background: #ceeefa99;
              span{
                color:#28a5dc;
              }
            }
            span{
              font-size: 14px;
              color: #363636;
              margin-top: 8px;
              transition: .2s;
            }
            a{
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .style-detail{
      >div{
        &:nth-child(odd){
          background: #fdfdfd;
        }
        &:nth-child(even){
          background: #fafafa;
        }
        >div{
          width: 1200px;
          margin: 0 auto;
          padding: 216px 0 84px;
        }
      }
    }
    .join-us{
      height: 220px;
      background: #fafafa;
    }
  }
</style>
